import React from "react";
import { ServicesItemContainer } from "./ServicesItemContainer";
import { ServicesTitle } from "./ServicesTitle";
import { ServicesDetails } from "./ServicesDetails";
import { Image } from "../../shared/Image";
import ICONOC_MAYTECH_12 from "../../../assets/img/ICONOC_MAYTECH-12.webp";
import ICONOC_MAYTECH_13 from "../../../assets/img/ICONOC_MAYTECH-13.webp";
import ICONOC_MAYTECH_14 from "../../../assets/img/ICONOC_MAYTECH-14.webp";

export class ServicesDownItems extends React.Component {
  render() {
    return (
      <div className="row mb-2 text-center d-flex justify-content-center text-uppercase">
        <ServicesItemContainer
          anima="anima_up2"
          extraClass="unique-container-class"
        >
          <Image css="pt-2 pb-2" src={ICONOC_MAYTECH_13} alt="renovables" />
          <ServicesTitle>Energías Renovables</ServicesTitle>
          <ServicesDetails>
            Aprovecha los recursos naturales y ahorra electricidad
          </ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer anima="anima_up">
          <Image css="pt-2" src={ICONOC_MAYTECH_12} alt="incendios" />
          <ServicesTitle>Sistema contra incendios</ServicesTitle>
          <ServicesDetails>
            Prevención automática de humo e incendios
          </ServicesDetails>
        </ServicesItemContainer>

        <ServicesItemContainer
          anima="anima_up2"
          extraClass="unique-container-class"
        >
          <Image css="pt-2 pb-2" src={ICONOC_MAYTECH_14} alt="redes it" />
          <ServicesTitle>Redes IT y cableado estructurado</ServicesTitle>
          <ServicesDetails>
            Implementación de tecnología IT para tu negocio
          </ServicesDetails>
        </ServicesItemContainer>
      </div>
    );
  }
}
