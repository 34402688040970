import React from "react";
import belden from "../../assets/brands/Belden.webp";
import condumex from "../../assets/brands/Condumex.webp";
import dks from "../../assets/brands/DKS.webp";
import epcom from "../../assets/brands/Epcom.webp";
import faac from "../../assets/brands/FAAC.webp";
import grandstream from "../../assets/brands/GrandStream.webp";
import hangua from "../../assets/brands/Hangua.webp";
import hikvision from "../../assets/brands/Hikvision.webp";
import honeywell from "../../assets/brands/Honeywell.webp";
import idis from "../../assets/brands/IDIS.webp";
import linkedpro from "../../assets/brands/Linkedpro.webp";
import samsung from "../../assets/brands/Samsung.webp";
import tplink from "../../assets/brands/TP-link.webp";
import ubiquiti from "../../assets/brands/Ubiquiti.webp";
import ezviz from "../../assets/img/31.webp";
import accessPro from "../../assets/img/32.webp";
import cameIt from "../../assets/img/33.webp";
import zktEco from "../../assets/img/34.webp";
import { Image } from "../shared/Image";

export class Slider extends React.Component {
  render() {
    const images = [
      belden,
      condumex,
      dks,
      epcom,
      faac,
      grandstream,
      hangua,
      hikvision,
      honeywell,
      idis,
      linkedpro,
      samsung,
      tplink,
      ubiquiti,
      ezviz,
      accessPro,
      cameIt,
      zktEco,
    ];

    return (
      <div className="slider">
        <div className="slide-track">
          {images.concat(images).map((src, index) => (
            <div className="slide" key={index}>
              <Image css="brand" src={src} alt={`logo ${index}`} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
