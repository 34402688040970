import React from "react";
import { Link } from "react-router-dom";

export class Dashboard extends React.Component {
  render() {
    return (
      <div className="admin-container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-12">
            <label className="add-post-title text-uppercase p-0 m-0">
              ¡Bienvenido a tu página de inicio!
            </label>
            <br />
            <label className="add-post-txt text-uppercase pb-3">
              Este es tu centro de operaciones para publicar y editar las
              entradas a tu blog
            </label>
          </div>
        </div>
        <div className="row justify-content-center mb-3">
          <div className="col-md-6 col-sm-12 text-uppercase welcome bg-lightshadow py-4">
            <h1 className="font-weight-bold txt-navyblue">
              Publica nuevas entradas en tu blog
            </h1>
            <p className="mt-2">
              En el panel de entradas podrás publicar, editar y eliminar las
              entradas de tu blog
            </p>
            <Link
              to="/admin/blog/list"
              className="btn btn-custom btn-blue-cta mt-2 px-4 py-3"
            >
              Ir al panel de entradas
              <i className="fas fa-chevron-right font-weight-bolder ml-3"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
