import React from 'react';

export class ButtonCustom extends React.Component{
    render() {
        return(
            <a 
            className={`btn btn-custom btn-${this.props.color}`}
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1000"
            href={`${this.props.href}`} 
            target="_blank" rel="noopener noreferrer">
                {this.props.children}
            </a>
        );
    }
}