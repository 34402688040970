import React from "react";
import alma from "../../assets/clients/Alma.webp";
import vidanta from "../../assets/clients/Vidanta.webp";
import jardines from "../../assets/clients/Jardines.webp";
import playacar from "../../assets/clients/Playacar.webp";
import plaza from "../../assets/clients/Plaza.webp";
import cerma from "../../assets/clients/Cerma.webp";
import corasol from "../../assets/clients/Corasol.webp";
import jv from "../../assets/clients/JV.webp";
import clandestino from "../../assets/clients/Clandestino.webp";
import bbcondos from "../../assets/clients/BBCondos.webp";
import ado from "../../assets/clients/ADO.webp";
import zy from "../../assets/clients/zy.webp";
import cerio from "../../assets/clients/cerio.webp";
import cuidado_perro from "../../assets/clients/cuidado_perro.webp";
//import humana from "../../assets/clients/humana.webp";
import kanai from "../../assets/clients/kanai.webp";
import casa_kaoba from "../../assets/clients/casa-kaoba.webp";
import mayakoba from "../../assets/clients/mayakoba.webp";
import xambe from "../../assets/clients/xambe.webp";
import real_amalfi from "../../assets/clients/real-amalfi.webp";
import { Image } from "../shared/Image";

export class Clients extends React.Component {
  render() {
    return (
      <div
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1000"
        className="col pt-md-3 test-clients"
      >
        <div className="col mb-3 d-flex align-items-center justify-content-between">
          <Image css="client client1" src={alma} alt="logo alma tulum" />
          <Image css="client client2" src={vidanta} alt="logo vidanta" />
          <Image css="client client12" src={jardines} alt="logo jardines" />
          <Image css="client client12" src={playacar} alt="logo playacar" />
          <Image css="client client12" src={plaza} alt="logo plaza congreso" />
        </div>
        <div className="col mb-3 d-flex align-items-center justify-content-around">
          <Image css="client client6" src={cerma} alt="logo grupo cerma" />
          <Image css="client client7" src={corasol} alt="logo corasol" />
          <Image css="client client8" src={jv} alt="logo jv" />
          <Image
            css="client client9"
            src={clandestino}
            alt="logo clandestino"
          />
          <Image css="client client10" src={bbcondos} alt="logo bb condos" />
        </div>
        <div className="col mb-sm-3 d-flex align-items-center justify-content-around">
          <Image css="client client11" src={ado} alt="logo ado" />
          <Image css="client client11" src={zy} alt="logo zy" />
          <Image css="client client12" src={cerio} alt="logo cerio" />
          <Image css="client client8" src={kanai} alt="logo kanai" />
          {/* <Image css="client client8" src={humana} alt="logo humana" /> */}
          <Image
            css="client client8"
            src={cuidado_perro}
            alt="logo cuidado con el perro"
          />
        </div>
        <div className="col mb-sm-3 d-flex align-items-center justify-content-between">
          <Image css="client client13" src={casa_kaoba} alt="logo casa_kaoba" />
          <Image css="client client11" src={mayakoba} alt="logo mayakoba" />
          <Image css="client client11" src={xambe} alt="logo xambe" />
          <Image
            css="client client12"
            src={real_amalfi}
            alt="logo real_amalfi"
          />
        </div>
      </div>
    );
  }
}
