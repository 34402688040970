import React from "react";
import logo from "../../assets/img/Logo.webp";
import { Link } from "react-scroll";
import arrowDown from "../../assets/svg/arrowDown.png";

export class Masterhead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPhraseIndex: 0,
      currentSpanIndex: 0,
    };

    this.phrases = [
      "SEGURIDAD ELECTRÓNICA PARA",
      "MANTENEMOS TUS",
      "PUNTO DE",
      "TECNOLOGÍA, INNOVACIÓN",
    ];

    this.span = [
      "TUS PROYECTOS",
      "ESPACIOS SEGUROS",
      "SEGURIDAD",
      "& CONFIANZA",
    ];
  }

  componentDidMount() {
    this.interval = setInterval(this.changePhraseAndSpan, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changePhraseAndSpan = () => {
    this.setState((prevState) => ({
      currentPhraseIndex:
        (prevState.currentPhraseIndex + 1) % this.phrases.length,
      currentSpanIndex: (prevState.currentSpanIndex + 1) % this.span.length,
    }));
  };

  render() {
    const { currentPhraseIndex, currentSpanIndex } = this.state;
    const currentPhrase = this.phrases[currentPhraseIndex];
    const currentSpan = this.span[currentSpanIndex];

    return (
      <header id="home" className="masthead">
        <div id="homeText" className="container px-0 mb-3">
          <img
            loading="lazy"
            className="mb-3 animate__animated animate__fadeInLeft animate__delay-1s master-head-logo"
            src={logo}
            alt="logo maytech"
          />
          <div className="d-flex flex-row align-items-center animate__animated animate__fadeInLeft animate__delay-2s">
            <div className="divisionLine mr-2"></div>
            <div className="masthead-subheading text-uppercase font-weight-bold">
              Sistemas diseñados para tu necesidad
            </div>
          </div>
          <div className="masthead-heading text-uppercase">
            {currentPhrase}{" "}
            <div className="titleHighlighted font-weight-bold animate__animated animate__fadeInLeft animate__delay-1s">
              {currentSpan}
            </div>
          </div>
        </div>
        <Link
          id="arrowHome"
          style={{ cursor: "pointer" }}
          className="nav-link"
          activeClass="active"
          to="info"
          spy={true}
          smooth={true}
          offset={0}
          duration={900}
        >
          <img
            loading="lazy"
            className="arrowDown"
            src={arrowDown}
            alt="arrow down"
          />
        </Link>
      </header>
    );
  }
}
