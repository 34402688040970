const CookieHelper = {
    Get: (key) => {
        const cookie = localStorage.getItem(key);
        const value = JSON.parse(cookie);
        return value;
    },
    Set: (key, data) => {
        localStorage.setItem(key, JSON.stringify(data));
    },
    Remove: (key) => {
        localStorage.removeItem(key);
    }
}

export default CookieHelper;