import React from "react";
import { PostTitle } from "../../components/home/PostTitle";
import { PostDate } from "../../components/home/PostDate";
import { Link } from "react-router-dom";
import { Icon } from "../../components/shared/Icon";
import { Footer } from "../../components/shared/Footer";
import Swal from "sweetalert2";
import UsuarioService from "../../services/UsuarioService";
import JsHelper from "../../helpers/JsHelper";
import ServerHelper from "../../helpers/ServerHelper";

export class BlogPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      blog: {},
      comments: [],
      nombre: "",
      comentario: "",
    };

    this.SetNombre = this.SetNombre.bind(this);
    this.SetComentario = this.SetComentario.bind(this);
    this.AddComment = this.AddComment.bind(this);
    this.DeleteComment = this.DeleteComment.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.GetBlog();
  }

  componentDidUpdate() {
    if (!UsuarioService.IsAuthenticated) {
      let elements = JsHelper.getAllBySelector(".delete-btn");
      Array.prototype.forEach.call(elements, (node) => {
        node.parentNode.removeChild(node);
      });
    }
  }

  GetBlog = async () => {
    const id = this.state.id;
    let response = await fetch(`${ServerHelper.ApiUrl}/blog/getbyid?id=${id}`);

    if (response.status === 200) {
      let blog = await response.json();
      console.log(blog[0]);
      this.setState({
        blog: blog[0],
      });
    }

    response = await fetch(
      `${ServerHelper.ApiUrl}/blog/getcomments?idArticulo=${id}`
    );

    if (response.status === 200) {
      this.setState({
        comments: await response.json(),
      });
    }
  };

  AddComment = async () => {
    let message = this.ValidarCampos();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "/" + dd;
    if (message === null) {
      const data = {
        id_articulo: this.state.id,
        nombre: this.state.nombre,
        comentario: this.state.comentario,
        fecha: today,
      };
      const response = await fetch(`${ServerHelper.ApiUrl}/blog/addcomment`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        this.GetBlog();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops, ocurrió un error",
          text: "No pudimos publicar el comentario",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: message,
      });
    }
  };

  DeleteComment = async (id) => {
    const data = {
      id: id,
      id_articulo: this.state.id,
    };
    const response = await fetch(`${ServerHelper.ApiUrl}/blog/deletecomment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      this.GetBlog();
    } else {
      Swal.fire({
        icon: "error",
        text: "Error al eliminar el comentario",
      });
    }
  };

  SetNombre(event) {
    this.setState({
      nombre: event.target.value,
    });
  }

  SetComentario(event) {
    this.setState({
      comentario: event.target.value,
    });
  }

  ValidarCampos() {
    let message = null;

    const nombre = this.state.nombre;
    const comentario = this.state.comentario;

    if (nombre === "") {
      message = "Debe completar el nombre";
    } else if (comentario === "") {
      message = "Debe completar el comentario";
    }

    return message;
  }

  renderComments(comments, showDelete) {
    return (
      <div>
        {comments.map((comment) => (
          <div className="card-body p-0" key={comment.id}>
            <div className="border p-2 mb-2">
              <h6 className="card-subtitle mb-2 text-muted">
                {comment.nombre}{" "}
                <span style={{ fontSize: "8px" }}>
                  <br />
                  {JsHelper.dateFormatting(comment.fecha)}
                </span>
              </h6>
              <p className="card-text">{comment.comentario}</p>
            </div>
            <button
              type="button"
              className="btn btn-custom btn-danger delete-btn"
              onClick={() => this.DeleteComment(comment.id)}
            >
              Eliminar
            </button>
          </div>
        ))}
      </div>
    );
  }

  render() {
    let html = this.state.blog.texto;
    let titulo = this.state.blog.titulo;
    let fecha =
      this.state.blog.fecha !== undefined
        ? JsHelper.dateFormatting(this.state.blog.fecha)
        : this.state.blog.fecha;
    let comments = this.renderComments(this.state.comments);
    return (
      <div>
        <div className="blogPage w-100 sectionPadding">
          <h3 className="sectionTitle text-white text-uppercase fjalla">
            Maytech Blog
          </h3>
          <div className="d-flex flex-column justify-content-between">
            <div className="blog-post-container d-flex flex-column justify-content-between text-justify bg-light my-2 p-4">
              <div>
                <PostTitle>{titulo}</PostTitle>
                <PostDate>{fecha}</PostDate>
                <br />
              </div>
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
              <p className="fjalla text-uppercase mb-2">Comentarios</p>
              {comments}
              <Link
                to="/blog"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  marginTop: "1rem",
                }}
              >
                <Icon icon="chevron-left" css="mr-2"></Icon>Ir al listado de
                blogs
              </Link>
            </div>
            <div className="bg-light d-flex flex-column justify-content-between text-justify bg-light my-2 p-4">
              <div>
                <PostTitle>Añadir comentario</PostTitle>
                <br />
                <PostDate>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tu nombre"
                    onChange={this.SetNombre}
                  ></input>
                </PostDate>
                <br />
                <PostDate>
                  <textarea
                    rows="5"
                    cols="5"
                    style={{ resize: "none" }}
                    className="form-control"
                    placeholder="Tu comentario"
                    onChange={this.SetComentario}
                  ></textarea>
                </PostDate>
                <br />
                <button
                  type="button"
                  className="btn btn-custom btn-blue-cta px-3 py-2"
                  onClick={this.AddComment}
                >
                  Enviar comentario
                  <i className="fas fa-chevron-right font-weight-bolder ml-3"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
