import React from "react";
import { Icon } from "../shared/Icon";
import Swal from "sweetalert2";
import ServerHelper from "../../helpers/ServerHelper";

export class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_name: "",
      form_phone: "",
      form_email: "",
      form_message: "",
      loading: false,
    };
    this.setName = this.setName.bind(this);
    this.setPhone = this.setPhone.bind(this);
    this.setMail = this.setMail.bind(this);
    this.setMessage = this.setMessage.bind(this);

    this.sendEmail = this.sendEmail.bind(this);
  }

  setName(event) {
    this.setState({
      form_name: event.target.value,
    });
  }
  setPhone(event) {
    this.setState({
      form_phone: event.target.value,
    });
  }
  setMail(event) {
    this.setState({
      form_email: event.target.value,
    });
  }
  setMessage(event) {
    this.setState({
      form_message: event.target.value,
    });
  }

  isFormValid() {
    const { form_name, form_phone, form_email, form_message } = this.state;
    return form_name && form_phone && form_email && form_message;
  }

  sendEmail = async (form) => {
    this.setState({
      loading: true,
    });
    const data = {
      name: form.form_name,
      phone: form.form_phone,
      email: form.form_email,
      message: form.form_message,
    };
    const url = `${ServerHelper.ApiUrl}/contact/sendEmail`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title:
          "<h3>¡Tu mensaje se envió exitosamente!</h3> <h5>Estaremos comunicándonos a la brevedad posible</h5>",
        timer: 2000,
      });

      this.setState({ form_name: "" });
      this.setState({ form_phone: "" });
      this.setState({ form_email: "" });
      this.setState({ form_message: "" });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Parece que algo salió mal, intentalo nuevamente",
        timer: 2000,
      });
    }
    this.setState({
      loading: false,
    });
  };

  renderSendButton() {
    return (
      <button
        type="submit"
        className="btn btn-enviar my-3 font-weight-bold text-uppercase"
        onClick={(e) => {
          e.preventDefault();
          this.sendEmail(this.state);
        }}
        disabled={!this.isFormValid()}
        style={{
          cursor: this.isFormValid() ? "pointer" : "not-allowed",
        }}
      >
        Enviar
      </button>
    );
  }

  renderSendLoading() {
    return (
      <button
        type="button"
        className="btn btn-enviar my-3 font-weight-bold text-uppercase"
        disabled="disabled"
      >
        Enviando...
        <Icon icon="sync" css="fa-spin ml-2" />
      </button>
    );
  }

  showSendButton(loading) {
    if (loading) {
      return this.renderSendLoading();
    } else {
      return this.renderSendButton();
    }
  }

  render() {
    return (
      <div className="col-md-6 formContainer">
        <form
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="text-white py-3 px-lg-4"
        >
          <div className="form-row text-uppercase">
            <div className="form-group col-md-6 mb-0">
              <label className="font-weight-bold">Nombre</label>
              <input
                value={this.state.form_name}
                type="text"
                className="form-control"
                id="name"
                placeholder="Ingresa tu nombre"
                maxLength="25"
                onChange={this.setName}
                required
              />
            </div>

            <div className="form-group col-md-6 mb-0">
              <label className="font-weight-bold">Telefóno</label>
              <input
                value={this.state.form_phone}
                type="numeric"
                className="form-control"
                id="inputPhone"
                placeholder="Ingresa tu teléfono"
                maxLength="14"
                onChange={this.setPhone}
                required
              />
            </div>
          </div>

          <div className="form-group text-uppercase mb-0">
            <label className="font-weight-bold">Correo</label>
            <input
              value={this.state.form_email}
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Ingresa tu correo electrónico"
              onChange={this.setMail}
              required
            />
          </div>

          <div className="form-group text-uppercase mb-0">
            <label className="font-weight-bold">Mensaje</label>
            <textarea
              value={this.state.form_message}
              type="text"
              className="form-control"
              id="inputText"
              placeholder="Déjanos tu mensaje"
              onChange={this.setMessage}
              maxLength="250"
              required
            />
          </div>

          {this.showSendButton(this.state.loading)}

          <div className="form-row form-info d-flex flex-column formDirection p-2 font-weight-bold">
            <div className="d-flex align-items-center mb-2">
              <i className="fas fa-map-marker-alt pr-2"></i>
              <p className="m-0">Playa del Carmen, Q. Roo - México</p>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fas fa-phone pr-2"></i>
              <p className="m-0">+52 1 (984) 287 9394</p>
            </div>
            <div className="d-flex align-items-center">
              <i className="fas fa-envelope pr-2"></i>
              <a
                href="mailto:contacto@maytechseguridad.com"
                className="m-0 contact-text"
                style={{ fontSize: "14px" }}
              >
                contacto@maytechseguridad.com
              </a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
