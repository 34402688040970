import React, { Fragment } from "react";
import { PostContainer } from "../components/home/PostContainer";
import { PostTitle } from "../components/home/PostTitle";
import { PostDate } from "../components/home/PostDate";
import { PostReadMore } from "../components/home/PostReadMore";
import { Link } from "react-router-dom";
import { Footer } from "../components/shared/Footer";
import JsHelper from "../helpers/JsHelper";
import ServerHelper from "../helpers/ServerHelper";

export class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      blogs: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.GetBlogs();
  }

  GetBlogs = async () => {
    const response = await fetch(`${ServerHelper.ApiUrl}/blog/getall`);

    if (response.status === 200) {
      let blogs = await response.json();
      console.log(blogs);
      this.setState({
        blogs: blogs,
      });
    }
  };

  renderBlog(blogs) {
    return (
      <Fragment>
        {blogs.map((blog) => (
          <PostContainer css="col" key={blog.id}>
            <Link
              to={`/blog/entry/${blog.id}`}
              style={{ textDecoration: "none", color: "#000" }}
            >
              <div>
                <PostTitle>{blog.titulo}</PostTitle>
                <PostDate>{JsHelper.dateFormatting(blog.fecha)}</PostDate>
              </div>
              <div>
                <PostReadMore></PostReadMore>
              </div>
            </Link>
          </PostContainer>
        ))}
      </Fragment>
    );
  }

  render() {
    let blogs = this.renderBlog(this.state.blogs);
    return (
      <div className="mainContent">
        <div className="blogPage w-100 sectionPadding">
          <h3 className="sectionTitle text-white text-uppercase fjalla">
            Mantente actualizado
          </h3>
          <p className="sectionDescription text-white text-uppercase font-weight-bold">
            Te brindamos la mejor información sobre tecnologpia y las
            herramientas más útiles para mantenerte protegido
          </p>
          <div className="d-flex flex-column justify-content-between">
            {blogs}
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
