import React from "react";
import msn from "../../assets/img/Msn.webp";
import whatsapp from "../../assets/img/Whatsapp.webp";
import email from "../../assets/img/Email.webp";

export class Contact extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column contactItemsContainer">
        <a
          className="animate__animated animate__fadeInUp animate__delay-1s rss messengerIcon"
          href="https://m.me/maytechseguridad"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            loading="lazy"
            src={msn}
            className="my-float"
            alt="facebook messenger logo"
          />
        </a>
        <a
          className="animate__animated animate__fadeInUp animate__delay-3s rss emailIcon"
          href="#contact"
          rel="noopener noreferrer"
        >
          <img
            loading="lazy"
            src={email}
            className="my-float"
            alt="email logo"
          />
        </a>
        <a
          className="animate__animated animate__fadeInUp animate__delay-2s rss whats-app"
          href="https://api.whatsapp.com/send?phone=529842879394&text=Hola%2C%20deseo%20obtener%20mayor%20informaci%C3%B3n%20de%20Maytech%20Seguridad%20electr%C3%B3nica."
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            loading="lazy"
            src={whatsapp}
            className="my-float"
            alt="whatsapp logo"
          />
        </a>
      </div>
    );
  }
}
