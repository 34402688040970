import React from 'react';

export class InfoItem extends React.Component {
  render(){
      return(
        <div 
        className="col-3 p-0 infoItem"
        data-aos={`${this.props.aos}`}
        data-aos-delay={`${this.props.delay}`}
        data-aos-duration={`${this.props.duration}`}
        >
          {this.props.children}
        </div>
      );
  }
}